import { ThemeTypographyKeys } from '@agria/theme/src/styles/tokens/typography';
import { ThemeBorderRadiiKeys } from '@agria/theme/src/styles/tokens/borderRadii';
import { ThemeColorsKeys } from '@agria/theme/src/styles/tokens/colors';
import defaultTokens from '@agria/theme/src/styles/tokens/buttons';

interface ThemeButtonBase {
  color: ThemeColorsKeys;
  background: ThemeColorsKeys;
  borderColor: ThemeColorsKeys;
  borderRadius: ThemeBorderRadiiKeys;
  underline?: boolean;
  collapse?: boolean;
  upperCase?: boolean;
}

interface ThemeButton extends ThemeButtonBase {
  type: ThemeTypographyKeys;
  hover: ThemeButtonBase;
}

const primaryBase = {
  background: 'brandTwo100',
  color: 'brandOne100',
  upperCase: true,
  hover: {
    background: 'brandTwo120',
    borderColor: 'brandTwo120',
  },
};

const buttons = {
  ...defaultTokens,
  primary: {
    borderColor: 'brandTwo100',
    ...primaryBase,
  },
  primaryOnDark: {
    borderColor: 'brandTwo50',
    ...primaryBase,
  },
  primaryOutline: {
    borderColor: 'brandTwo100',
    background: 'transparent',
    color: 'brandTwo100',
    upperCase: true,
    hover: {
      borderColor: 'brandTwo120',
      background: 'brandTwo120',
      color: 'utilityWhite',
    },
  },
  secondary: {
    borderColor: 'brandOne100',
    background: 'brandOne100',
    color: 'utilityWhite',
    upperCase: true,
    hover: {
      borderColor: 'brandOne120',
      background: 'brandOne120',
    },
  },
  secondaryOutline: {
    borderColor: 'brandOne100',
    background: 'transparent',
    color: 'brandOne100',
    upperCase: true,
    hover: {
      borderColor: 'brandOne100',
      background: 'brandOne10',
      color: 'brandOne100',
    },
  },
  secondaryInverse: {
    borderColor: 'utilityWhite',
    background: 'utilityWhite',
    color: 'brandOne100',
    upperCase: true,
    hover: {
      borderColor: 'brandOne100',
      background: 'brandOne100',
    },
  },
  secondaryInverseOutline: {
    borderColor: 'utilityWhite',
    background: 'transparent',
    color: 'utilityWhite',
    upperCase: true,
    hover: {
      borderColor: 'utilityWhite',
      background: 'utilityWhite',
      color: 'brandOne100',
    },
  },
} as const;

export default buttons;

export type ThemeButtonKeys = keyof typeof buttons;
export type ThemeButtons = Record<ThemeButtonKeys, ThemeButton>;
